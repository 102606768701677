<template>
  <div>
    <header-top-dashboard primary></header-top-dashboard>
    <v-container class="px-6 mt-n16">
      <v-row>
        <v-col class="mx-auto pt-0" lg="8">
          <v-card class="card-shadow mb-30">
            <div class="card-header-padding card-border-bottom">
              <p class="font-weight-600 text-h3 text-typo mb-0">Icons</p>
            </div>
            <v-card-text class="card-padding">
              <v-row>
                <v-col
                  v-for="(icon, index) in icons"
                  :key="icon.name + index"
                  cols="12"
                  md="6"
                  xl="3"
                  class="py-0"
                >
                  <v-tooltip top color="#212529">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        block
                        elevation="0"
                        :ripple="false"
                        height="72"
                        @click="onCopy(icon.name)"
                        class="text-lowercase btn-secondary btn-icon-clipboard shadow-none pa-6 rounded-sm my-2"
                        color="#f8f9fa"
                      >
                        <v-icon size="24" class="me-3">{{ icon.name }}</v-icon>
                        {{ icon.name.substring(6) }}
                      </v-btn>
                    </template>
                    <span>Copy to clipboard</span>
                  </v-tooltip>
                  <v-text-field
                    class="opacity-0 position-absolute"
                    :value="icon.name"
                    :id="icon.name"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import HeaderTopDashboard from "@/components/HeaderTopDashboard.vue";

export default {
  name: "icons",
  components: {
    HeaderTopDashboard,
  },
  data() {
    return {
      icons: [
        { name: "ni ni-active-40" },
        { name: "ni ni-air-baloon" },
        { name: "ni ni-album-2" },
        { name: "ni ni-align-center" },
        { name: "ni ni-align-left-2" },
        { name: "ni ni-ambulance" },
        { name: "ni ni-app" },
        { name: "ni ni-archive-2" },
        { name: "ni ni-atom" },
        { name: "ni ni-badge" },
        { name: "ni ni-bag-17" },
        { name: "ni ni-basket" },
        { name: "ni ni-bell-55" },
        { name: "ni ni-bold-down" },
        { name: "ni ni-bold-left" },
        { name: "ni ni-bold-right" },
        { name: "ni ni-bold-up" },
        { name: "ni ni-bold" },
        { name: "ni ni-book-bookmark" },
        { name: "ni ni-books" },
        { name: "ni ni-box-2" },
        { name: "ni ni-briefcase-24" },
        { name: "ni ni-building" },
        { name: "ni ni-bulb-61" },
        { name: "ni ni-bullet-list-67" },
        { name: "ni ni-bus-front-12" },
        { name: "ni ni-button-pause" },
        { name: "ni ni-button-play" },
        { name: "ni ni-button-power" },
        { name: "ni ni-calendar-grid-58" },
        { name: "ni ni-camera-compact" },
        { name: "ni ni-caps-small" },
        { name: "ni ni-cart" },
        { name: "ni ni-chart-bar-32" },
        { name: "ni ni-chart-pie-35" },
        { name: "ni ni-chat-round" },
        { name: "ni ni-check-bold" },
        { name: "ni ni-circle-08" },
        { name: "ni ni-cloud-download-95" },
        { name: "ni ni-cloud-upload-96" },
        { name: "ni ni-compass-04" },
        { name: "ni ni-controller" },
        { name: "ni ni-credit-card" },
        { name: "ni ni-curved-next" },
        { name: "ni ni-delivery-fast" },
        { name: "ni ni-diamond" },
        { name: "ni ni-email-83" },
        { name: "ni ni-fat-add" },
        { name: "ni ni-fat-delete" },
        { name: "ni ni-fat-remove" },
        { name: "ni ni-favourite-28" },
        { name: "ni ni-folder-17" },
        { name: "ni ni-glasses-2" },
        { name: "ni ni-hat-3" },
        { name: "ni ni-headphones" },
        { name: "ni ni-html5" },
        { name: "ni ni-istanbul" },
        { name: "ni ni-key-25" },
        { name: "ni ni-laptop" },
        { name: "ni ni-like-2" },
        { name: "ni ni-lock-circle-open" },
        { name: "ni ni-map-big" },
        { name: "ni ni-mobile-button" },
        { name: "ni ni-money-coins" },
        { name: "ni ni-note-03" },
        { name: "ni ni-notification-70" },
        { name: "ni ni-palette" },
        { name: "ni ni-paper-diploma" },
        { name: "ni ni-pin-3" },
        { name: "ni ni-planet" },
        { name: "ni ni-ruler-pencil" },
        { name: "ni ni-satisfied" },
        { name: "ni ni-scissors" },
        { name: "ni ni-send" },
        { name: "ni ni-settings-gear-65" },
        { name: "ni ni-settings" },
        { name: "ni ni-single-02" },
        { name: "ni ni-single-copy-04" },
        { name: "ni ni-sound-wave" },
        { name: "ni ni-spaceship" },
        { name: "ni ni-square-pin" },
        { name: "ni ni-support-16" },
        { name: "ni ni-tablet-button" },
        { name: "ni ni-tag" },
        { name: "ni ni-tie-bow" },
        { name: "ni ni-time-alarm" },
        { name: "ni ni-trophy" },
        { name: "ni ni-tv-2" },
        { name: "ni ni-umbrella-13" },
        { name: "ni ni-user-run" },
        { name: "ni ni-vector" },
        { name: "ni ni-watch-time" },
        { name: "ni ni-world" },
        { name: "ni ni-zoom-split-in" },
        { name: "ni ni-collection" },
        { name: "ni ni-image" },
        { name: "ni ni-shop" },
        { name: "ni ni-ungroup" },
        { name: "ni ni-world-2" },
        { name: "ni ni-ui-04" },
      ],
    };
  },
  methods: {
    onCopy(el) {
      var test = document.getElementById(el);
      test.select();
      document.execCommand("copy");
    },
  },
};
</script>
